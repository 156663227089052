/** Ionic CSS Variables **/
@import "gothamfont.css";
:root {
  /* Set the font family of the entire app */
  --ion-font-family: "Gotham", sans-serif;
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;
  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  /* shopsaveearnmoney bg **/
  --ion-color-cbhbg: #95d2e7;
  --ion-color-cbhbg-rgb: 149,210,231;
  --ion-color-cbhbg-contrast: #000000;
  --ion-color-cbhbg-contrast-rgb: 0,0,0;
  --ion-color-cbhbg-shade: #83b9cb;
  --ion-color-cbhbg-tint: #a0d7e9;
}
:root .ion-color-cbhbg {
  --ion-color-base: var(--ion-color-cbhbg);
  --ion-color-base-rgb: var(--ion-color-cbhbg-rgb);
  --ion-color-contrast: var(--ion-color-cbhbg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cbhbg-contrast-rgb);
  --ion-color-shade: var(--ion-color-cbhbg-shade);
  --ion-color-tint: var(--ion-color-cbhbg-tint);
}
:root .small-font {
  font-size: 3vw;
}
:root .medium-font {
  font-size: 3.5vw;
}
:root .lightgray-background {
  background-color: lightgray;
}
:root .text-underline {
  text-decoration: underline;
}
:root .top-line {
  border-top: 0.5px solid lightgreen;
}
:root .my-custom-modal-css {
  background: rgba(0, 0, 0, 0.5) !important;
  padding: 40px 15px 0px 15px !important;
}
:root .openshopurlpage-modal-css {
  align-items: center;
  justify-content: center;
}
:root .openshopurlpage-modal-css::part(content) {
  width: 80%;
  height: 30%;
}
:root .store-logo {
  max-width: 60%;
}
:root .cashback-text {
  font-size: 4vw;
  color: #00910E;
  font-weight: bold;
}
:root .cashrebate-text {
  font-size: 3vw;
  color: #00910E;
}
:root .store-name {
  font-size: 3vw;
}
:root .bold {
  font-weight: bold;
}
:root .color-red {
  color: #e72c2c;
}
:root .bgColor {
  background-color: #8fd0e4;
}
:root .uppercase {
  text-transform: uppercase !important;
}